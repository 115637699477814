@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/calibre-web-light.woff");
  font-weight: 200;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/calibre-web-regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/calibre-web-medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/calibre-web-semibold.woff");
  font-weight: 600;
}
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "calibre";
  font-size: 16px;
}

a {
  text-decoration: none;
}

button:focus {
  outline: none;
}
